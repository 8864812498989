import {Button} from "../../../components/catalyst/button";
import {gql, useMutation} from "@apollo/client";


const SET_WANT = gql`
  mutation SetWant($itemId: ID!, $want: Boolean!) {
    setWantItem(input: {want: $want, itemId: $itemId})
  }
`

const BaseWantButton = ({item, value, onWanted, children, ...rest}) => {

  const [setWant] = useMutation(SET_WANT)
  const { id } = item;
  const allowed = value === true ? item.canWant.value : item.canReject.value

  const wantItem = () => {
    setWant({variables: {itemId: id, want: value}})
    .then(response => {
      console.log("Mutation response: ", response);
      onWanted(id)
    })
    .catch(error => {
      console.error("Mutation error: ", error);
    });
  }

  return (
    allowed &&
    <Button {...rest} onClick={wantItem} disabled={!allowed}>
      {children}
    </Button>
  )
}

export default BaseWantButton