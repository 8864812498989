import {classNames, formatDate} from "../helpers";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {useEffect} from "react";
import _ from "lodash";


const QUERY = gql`
  query getCommentable($id:ID!) {
    me {
      id
      pictureUrl
      name
    }
    commentable(id: $id) {
      comments {
        body
        id
        createdAt
        user {
          id
          name
          pictureUrl
        }
      }
    }
  }
`

const ADD_COMMENT = gql`
  mutation AddComment($id: ID!, $text: String!) {
    addComment(input: {id: $id, comment: $text})
  }
`

const Discussion = ({id, onCommentAdded, disabled}) => {
  const [addComment] = useMutation(ADD_COMMENT)

  const [fetchComments, {loading, error, data}] = useLazyQuery(QUERY, {
    variables: {id: id},
    fetchPolicy: "network-only"
  })


  useEffect(() => {
    fetchComments()
  }, [])

  if (loading || error || _.isUndefined(data)) {
    return null
  }

  const {commentable, me: user} = data
  const {comments} = commentable
  const sortedComments = _(comments).sortBy("createdAt").value()

  const handleSubmit = (event) => {
    const text = event.target.comment.value
    addComment({variables: {id: id, text: text}})
    .then(response => {
      console.log("Mutation response: ", response);
      fetchComments()
      onCommentAdded()
    })
    .catch(error => {
      console.error("Mutation error: ", error);
    });
  }

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="border-b pb-5">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Discussion
        </h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">

        </p>
      </div>

      <ul role="list" className="space-y-6 mt-4">
        {sortedComments.map((comment, index) => (
          <li key={comment.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                index === comments.length - 1 ? 'h-6' : '-bottom-6',
                'absolute left-0 top-0 flex w-6 justify-center'
              )}
            >
              <div className="w-px bg-gray-200"/>
            </div>
            <>
              <img
                src={comment.user.pictureUrl}
                alt={comment.user.name}
                className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
              />
              <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                <div className="flex justify-between gap-x-4">
                  <div className="py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">{comment.user.name}</span> commented
                  </div>
                  <time dateTime={comment.createdAt}
                        className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                    {formatDate(comment.createdAt)}
                  </time>
                </div>
                <p className="text-sm leading-6 text-gray-500">{comment.body}</p>
              </div>
            </>
            {/*) : (*/}
            {/*  <>*/}
            {/*<div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">*/}
            {/*  {activityItem.type === 'paid' ? (*/}
            {/*    <CheckCircleIcon className="h-6 w-6 text-brand-600" aria-hidden="true"/>*/}
            {/*  ) : (*/}
            {/*    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"/>*/}
            {/*  )}*/}
            {/*</div>*/}
            {/*<p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">*/}
            {/*  <span*/}
            {/*    className="font-medium text-gray-900">{activityItem.person.name}</span> {activityItem.type}*/}
            {/*</p>*/}
            {/*<time dateTime={activityItem.dateTime} className="flex-none py-0.5 text-xs leading-5 text-gray-500">*/}
            {/*  {activityItem.date}*/}
            {/*</time>*/}
            {/*</>*/}
            {/*)}*/}
          </li>
        ))}
      </ul>

      {/* New comment form */}
      {!disabled &&
        <div className="mt-6 flex gap-x-3">
          <img
            src={user.pictureUrl}
            alt=""
            className="h-6 w-6 flex-none rounded-full bg-gray-50"
          />

          <form onSubmit={handleSubmit} className="relative flex-auto">
            <div
              className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-brand-600">
              <label htmlFor="comment" className="sr-only">
                Add your comment
              </label>
              <textarea
                rows={2}
                name="comment"
                id="comment"
                className="block w-full resize-none border-0 bg-transparent px-2 py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Add your comment..."
                defaultValue={''}
              />
            </div>

            <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
              <div className="flex items-center space-x-5">
              </div>
              <button
                type="submit"
                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Comment
              </button>
            </div>
          </form>
        </div>
      }

    </div>
  )
}

export default Discussion