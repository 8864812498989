import {classNames, formatDate} from "../../helpers";
import RevokeInviteButton from "./buttons/RevokeInviteButton";


const statuses = {
  'accepted': 'text-green-700 bg-green-50 ring-green-600/20',
  'sent': 'text-gray-600 bg-gray-50 ring-gray-500/10',
}

const InvitesTable = ({invites, onChange}) => {
  return (
    <div className="bg-white p-4 rounded-md shadow">
      <div className="border-b border-gray-200 pb-5">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Invites</h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">

        </p>
      </div>
      <ul className="divide-y divide-gray-100">
        {invites.map((invite) => {
            const status = invite.accepted ? 'accepted' : 'sent'
            return (
              <li key={invite.email} className="flex justify-between gap-x-6 py-5">
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold leading-6 text-gray-900">{invite.email}</p>
                      <p
                        className={classNames(
                          statuses[status],
                          'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset uppercase'
                        )}
                      >
                        {status}
                      </p>
                    </div>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">{formatDate(invite.updatedAt)}</p>
                  </div>
                </div>
                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  <RevokeInviteButton id={invite.id} onRevoked={onChange} disabled={!invite.canRevoke.value}/>
                </div>
              </li>
            )
          }
        )}
      </ul>
    </div>
  )

}

export default InvitesTable