import {gql, useMutation} from "@apollo/client";
import {Button} from "../../../components/catalyst/button";
import {TrashIcon} from "@heroicons/react/24/solid";


const DELETE_ITEM = gql`
  mutation deleteItem($id: ID!) {
    deleteItem(input: {id: $id})
  }
`

const DeleteItemButton = ({item, onDeleted, ...rest}) => {

  const [deleteItem] = useMutation(DELETE_ITEM)
  const {id} = item
  const allowed = item.canDelete.value

  const handleSubmit = () => {
    deleteItem({variables: {id: id}})
    .then(response => {
      console.log("Mutation response: ", response);
      onDeleted()
    })
    .catch(error => {
      console.error("Mutation error: ", error);
    });
  }

  return (
    allowed &&
    <Button onClick={handleSubmit} color="white"  {...rest} disabled={!allowed}>
      <TrashIcon className="stroke-red-500 fill-red-200"/>
    </Button>
  )
}

export default DeleteItemButton