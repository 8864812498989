import {gql, useLazyQuery} from "@apollo/client";
import _ from "lodash";
import PeopleTable from "./PeopleTable";
import InviteButton from "./buttons/InviteButton";
import {useEffect} from "react";
import InvitesTable from "./InvitesTable";

const QUERY = gql`
  query getUsers {
    users {
      name
      id
      email
      admin
      pictureUrl
    }
    estate {
      invites {
        id
        email
        accepted
        acceptedAt
        updatedAt
        canRevoke {
          value
        }
      }
      canInviteUser {
        value
      }
    }
  }
`

const People = () => {
  const [fetchPeople, {loading, error, data}] = useLazyQuery(QUERY, {
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    fetchPeople()
  }, [])

  const onChange = () => {
    fetchPeople()
  }

  if (loading || error || _.isUndefined(data)) {
    return null
  }

  const {users, estate} = data
  const {invites} = estate

  return (
    <>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            People
          </h2>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          <InviteButton onInvited={onChange} disabled={!estate.canInviteUser.value} />
        </div>
      </div>

      <div className="mt-8">
        <PeopleTable users={users}/>
      </div>

      <div className="mt-4">
        <InvitesTable invites={invites} onChange={onChange}/>
      </div>
    </>
  )
}

export default People