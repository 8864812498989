import {Link} from "react-router-dom";
import {formatMoney, userUrl} from "../helpers";
import {ArrowRight, CrownIcon} from "lucide-react";
import _ from "lodash";
import HelpBadge from "./HelpBadge";
import {useTranslation} from "react-i18next";


const Person = ({person}) => {

  return (
    <div className="flex min-w-0 gap-x-4">
      <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.pictureUrl} alt=""/>
      <div className="min-w-0 flex-auto">
        <div className="text-sm font-semibold leading-6 text-gray-900 flex items-center ">
          <Link to={userUrl(person)}>
            {person.name}
          </Link>
          {person.admin && <div title="admin"><CrownIcon className="size-4 ml-1 text-yellow-600"/></div>}
        </div>
        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.email}</p>
      </div>
    </div>
  )
}

const Debt = ({debt}) => {
  const {creditor, debtor, amount} = debt

  return (
    <>
      <Person person={debtor}/>
      <div className="flex items-center flex-col">
        {formatMoney(amount)}
        <ArrowRight className="size-5 stroke-2 stroke-green-500"/>
      </div>
      <Person person={creditor}/>
    </>
  )
}

const Ledger = ({title, debts, help}) => {
  const {t} = useTranslation()

  if (_.isEmpty(debts)) return null

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="border-b border-gray-200 pb-5 mb-4 flex flex-row">
        <h3 className="text-base font-semibold leading-6 text-gray-900 flex-1">
          {title}
        </h3>
        {help && <HelpBadge text={t(help)}/> }
      </div>


      <div className="inline-grid grid-cols-3 auto-cols-auto gap-x-3 gap-y-8">
        {debts.map((debt, i) => <Debt key={i} debt={debt}/>)}
      </div>
    </div>
  )
}

export default Ledger