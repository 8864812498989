import _ from "lodash";
import {Link} from "react-router-dom"
import {CheckIcon, EqualIcon, XIcon} from "lucide-react";

export const STATES = ["WON", "TIED", "GIFT", "REJECTED"]
export const USER_STATES = ["WON", "TIED", "LOST", "REJECTED"]
export const STATE_COLORS = {
  "TIED": "#fcd34d",
  "WON": "#65a30d",
  "GIFT": "#38bdf8",
  "REJECTED": "#d6d3d1",
}

const winIcon = <CheckIcon className="size-4 text-green-500 stroke-[3]"/>
const loseIcon = <XIcon className="size-4 text-red-500 stroke-[3]"/>
const tieIcon = <EqualIcon className="size-4 text-yellow-600 stroke-[3]"/>

export const leftButtonStyle = "relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 capitalize disabled:text-gray-300"
export const innerButtonStyle = "relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10  capitalize  disabled:text-gray-300"
export const rightButtonStyle = "relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 capitalize disabled:text-gray-300"


export const bidIcon = (item, user) => {
  const highestBids = (bids) => {
    const validBids = bids.filter(bid => bid.want)
    const highestBid = validBids.reduce((max, bid) => bid.price.amount > max ? bid.price.amount : max, 0)

    // Find all bids that are equal to the highest bid
    return validBids.filter(bid => bid.price.amount === highestBid);
  }


  const userBid = _.find(item.bids, bid => bid.user.id === user.id)
  const userPrice = _.isUndefined(userBid) ? 0 : userBid.price.amount
  const highest = highestBids(item.bids)
  const topBid = highest[0].price.amount

  if (userPrice < topBid) return loseIcon
  if (highest.length > 1) return tieIcon
  return winIcon
}


export const userUrl = (user) => `/people/${user.id}`

export const UserLink = ({user, ...rest}) => (
  <Link to={userUrl(user)} {...rest}>{user.name}</Link>
)

export const itemUrl = (item) => `/inventory/${item.id}`

export const ItemLink = ({item, ...rest}) => (
  <Link to={itemUrl(item)} className="underline" {...rest}>{item.name}</Link>
)

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export const formatMoney = (money, precision = 2) => {
  if (_.isNil(money)) return "N/A"

  const options = {
    style: "currency",
    currency: money.currency,
    currencyDisplay: "symbol",
    currencySign: "accounting",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision
  }
  const formatter = new Intl.NumberFormat("en-US", options)
  try {
    return formatter.format(money.amount)
  } catch (error) {
    // in case the currency is not standard (e.g. stock symbol)
    return money.currency + " " + Intl.NumberFormat("en-US", {style: "decimal"}).format(money.amount)
  }
}

export const formatDate = (date, options = {}) => {
  let x = date
  if (_.isString(date)) x = new Date(date)
  if (_.isNil(x) || _.isNaN(date)) return null

  const defaultOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: "numeric",
    minute: "numeric"
  }
  const format = new Intl.DateTimeFormat("en-US", _.assign({}, defaultOptions, options)).format
  return format(x)
}

