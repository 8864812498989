import {gql, useMutation} from "@apollo/client";
import {Button} from "../../../components/catalyst/button";
import {LucideRecycle} from "lucide-react";
import {useState} from "react";
import {Dialog, DialogActions, DialogDescription, DialogTitle} from "../../../components/catalyst/dialog";

const RESET = gql`
  mutation ResetEstate {
    resetEstate(input: {})
  }
`


const ResetButton = ({onReset, disabled, ...rest}) => {
  const [resetEstate] = useMutation(RESET)
  const [isDialogOpen, setDialogOpen] = useState(false);

  const reset = () => {
    setDialogOpen(false)
    resetEstate({variables: {}})
    .then(response => {
      console.log("Mutation response: ", response);
      onReset()
    })
    .catch(error => {
      console.error("Mutation error: ", error);
    });
  }

  return (
    <>
      {
      !disabled &&
      <Button {...rest} onClick={() => setDialogOpen(true)} disabled={disabled}>
        <LucideRecycle className="size-4"/>
        Reset
      </Button>
      }
      <Dialog open={isDialogOpen} onClose={setDialogOpen}>
        <DialogTitle>Reset Estate</DialogTitle>
        <DialogDescription>
          Resetting the estate will delete all items, invites, and comments. All users will remain unchanged.
        </DialogDescription>
        <DialogActions>
          <Button plain onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={reset}>Reset</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ResetButton



