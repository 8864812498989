import {HelpCircleIcon} from "lucide-react";
import {useState} from "react";

const HelpBadge = ({text}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative flex items-center ">
      <button
        className="p-1 cursor-help"
        onClick={() => {
          setShowTooltip(!showTooltip)
          setTimeout(() => setShowTooltip(false), 45 * 1000)
        }}
      >
        <HelpCircleIcon className="size-4"/>
      </button>

      {showTooltip &&
        (
          <div
            className="absolute top-full -right-1/2 mt-2 w-48 p-4 bg-yellow-50  rounded-md shadow-lg z-20 cursor-pointer text-sm text-yellow-800 ring-1 ring-inset ring-yellow-600/20"
            onClick={() => setShowTooltip(false)}
          >
            {text}
          </div>
        )
      }
    </div>
  )
}

export default HelpBadge