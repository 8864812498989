import {gql, useLazyQuery} from "@apollo/client";
import _ from "lodash";
import StateBadge from "./StateBadge";
import Discussion from "../../components/Discussion";
import Ledger from "../../components/Ledger";
import {formatMoney, STATES} from "../../helpers";
import {Box} from "../../components/Box";
import Locks from "./Locks";
import LockButton from "./buttons/LockButton";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Spinner from "../../components/Spinner";
import ResetButton from "./buttons/ResetButton";

const QUERY = gql`
  query getEstate {
    me {
      id
      allottedValue {
        amount
        currency
      }
    }
    estate {
      id
      state
      memberships {
        user {
          id
          name
          pictureUrl
        }
        locked
      }
      debts {
        debtor {
          admin
          id
          pictureUrl
          email
          name
        }
        creditor {
          admin
          id
          pictureUrl
          email
          name
        }
        amount {
          amount
          currency
        }
      }
      value {
        amount
        currency
      }
      items {
        state
        userState
        value {
          amount
          currency
        }
        bids {
          price {
            amount
            currency
          }
        }
        allotted {
          id
          name
        }
      }
      canLock {
        value
      }
      canReset {
        value
      }
    }
  }
`
const Estate = ({}) => {
  const { t } = useTranslation();

  const [fetchEstate, {loading, error, data}] = useLazyQuery(QUERY, {
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    fetchEstate()
  }, []);

  if (loading || error || _.isUndefined(data)) {
    if (error) {
      console.error(error)
    } else {
      return <Spinner/>
    }
    return null
  }

  const {estate, me: user} = data
  const {debts, items} = estate

  const partitions = _(items).groupBy('userState').value()
  const grouped = _.map(STATES, (key) => ({id: key, value: partitions[key]?.length || 0}))
  const canLock = estate.canLock.value
  const canReset = estate.canReset.value

  return (
    <div className="min-h-full">
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1 flex-row">
          <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Estate
          </div>
        </div>
        <StateBadge state={estate.state}/>
        {canLock &&
          <div className="mt-4 flex md:ml-4 md:mt-0 gap-x-2">
            <LockButton disabled={!canLock} onLocked={fetchEstate} color="white"/>
            <ResetButton disabled={!canReset} onReset={fetchEstate} color="red" />
          </div>
        }
      </div>


      <div className="flex flex-wrap flex-initial items-start gap-x-3 gap-y-3 mt-4">
        <Box name="Estate's Total Value" value={formatMoney(estate.value)} help="estate.estateTotalValue" />
        <Box name="Your Allotted Value" value={formatMoney(user.allottedValue)} help="estate.yourAllocatedValue" />
        <Box name="All Items" value={estate.items.length}/>
        <Box name="Items Allotted to you" value={partitions['WON']?.length || 0} help="estate.itemsAllocatedToYou"/>
        <Box name="Items You've Declined" value={partitions['REJECTED']?.length || 0} help="estate.itemsYouveDeclined"/>
        <Box name="Contested Items" value={partitions['TIED']?.length || 0} help="estate.contestedItems"/>
        <Box name="Outbid Items" value={partitions['LOST']?.length || 0} help="estate.outbidItems"/>
        <Box name="Lock Status" value={<Locks locks={estate.memberships}/>} help="estate.lockStatus"/>

        <Ledger title="Equitable Money Distribution" debts={debts} help="estate.equitableMoneyDistribution"/>
      </div>

      {/*<div className="flex flex-wrap flex-initial items-start gap-x-3 gap-y-3 mt-4">*/}
      {/*  {items.length > 0 &&*/}
      {/*    <>*/}
      {/*      <Box name="Items by State"*/}
      {/*           value={<ItemsByState className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 min-w-32"*/}
      {/*                                partitions={partitions}/>}/>*/}
      {/*      <Box name="Items by Price"*/}
      {/*           value={<ItemsByPrice className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 min-w-32"*/}
      {/*                                items={items}/>}/>*/}
      {/*    </>*/}
      {/*  }*/}
      {/*</div>*/}

      <div className="mt-4">
        <Discussion id={estate.id}/>
      </div>
    </div>
  )
}

export default Estate