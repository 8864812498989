import {Button} from "../../../components/catalyst/button";
import {gql, useMutation} from "@apollo/client";
import {TrashIcon} from "@heroicons/react/24/solid";
import _ from "lodash";

const REVOKE_INVITE = gql`
  mutation deleteInvite($id: ID!) {
    deleteInvite(input: {id: $id})
  }
`

const RevokeInviteButton = ({id, onRevoked, disabled}) => {
  const [revokeInvite] = useMutation(REVOKE_INVITE)

  const handleRevoke = (_event) => {
    revokeInvite({variables: {id: id}})
    .then(response => {
      console.log("Mutation response: ", response);
      if (_.isFunction(onRevoked)) onRevoked()
    })
    .catch(error => {
      console.error("Mutation error: ", error);
    });
  }

  return (
    <>
      <Button color="red" onClick={handleRevoke} outline disabled={disabled}>
        <TrashIcon/>
      </Button>
    </>
  )
}

export default RevokeInviteButton