import {gql, useMutation} from "@apollo/client";
import {Button} from "../../../components/catalyst/button";
import {LockIcon} from "lucide-react";

const SET_LOCK = gql`
  mutation SetLock {
    lockEstate(input: {})
  }
`

const LockButton = ({onLocked, disabled, ...rest}) => {
  const [setLock] = useMutation(SET_LOCK)

  const lockEstate = () => {
    setLock({variables: {}})
    .then(response => {
      console.log("Mutation response: ", response);
      onLocked()
    })
    .catch(error => {
      console.error("Mutation error: ", error);
    });
  }

  return (
    !disabled &&
    <Button {...rest} onClick={lockEstate} disabled={disabled}>
      <LockIcon className="size-4"/>
      Lock
    </Button>
  )
}

export default LockButton



