import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {
  ArchiveBoxIcon,
  Bars3Icon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {Link, Outlet, useLocation} from "react-router-dom";
import {classNames} from "./helpers";
import {HelpCircleIcon} from "lucide-react";

const navigation = [
  {name: 'Estate', href: "/estate", icon: HomeIcon, current: true},
  {name: 'Inventory', href: "/inventory", icon: ArchiveBoxIcon, current: false},
  {name: 'People', href: "/people", icon: UsersIcon, current: false},
]
const teams = [
  {id: 1, name: 'Support', href: 'mailto:support@arsawatt.com', icon: HelpCircleIcon},
  // { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  // { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
]

export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()

  const currentlySelected = (path) => {
    return path === location.pathname
  }

  return (
    <>
      <div className="min-h-lvh">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80"/>
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-brand-600 px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=white"
                        alt="EasyAllot"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    currentlySelected(item.href) ? 'bg-brand-700 text-white' : 'text-brand-200 hover:text-white hover:bg-brand-700',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      currentlySelected(item.href) ? 'text-white' : 'text-brand-200 group-hover:text-white',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          {/*<div className="text-xs font-semibold leading-6 text-brand-200">Your teams</div>*/}
                          <ul className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <Link
                                  to={team.href}
                                  className='text-brand-200 hover:text-white hover:bg-brand-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                >
                                  <team.icon
                                    className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-brand-400 bg-brand-500 text-[0.625rem] font-medium text-brand-200 hover:text-white"/>
                                  <span className="truncate text-brand-200 hover:text-white">{team.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-brand-600 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=white"
                alt="EasyAllot"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            currentlySelected(item.href)
                              ? 'bg-brand-700 text-white'
                              : 'text-brand-200 hover:text-white hover:bg-brand-700',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              currentlySelected(item.href) ? 'text-white' : 'text-brand-200 group-hover:text-white',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  {/*<div className="text-xs font-semibold leading-6 text-brand-200">Your teams</div>*/}
                  <ul className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <Link
                          to={team.href}
                          className={'text-brand-200 hover:text-white hover:bg-brand-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'}
                        >
                          <team.icon
                            className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg text-[0.625rem] font-medium text-white"/>
                          <span className="truncate">{team.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <Link
                    to="/logout"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-brand-700"
                  >
                    <span>Log Out</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-brand-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-brand-200 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">
            EasyAllot
          </div>
          {/*<Link to="#">*/}
          {/*  <span className="sr-only">Your profile</span>*/}
          {/*  <img*/}
          {/*    className="h-8 w-8 rounded-full bg-brand-700"*/}
          {/*    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
          {/*    alt=""*/}
          {/*  />*/}
          {/*</Link>*/}
        </div>

        <main className="py-10 lg:pl-72 min-h-lvh bg-brand-50">
          <div className="px-4 sm:px-6 lg:px-8 h-full ">
            <Outlet/>
          </div>
        </main>
      </div>
    </>
  )
}
