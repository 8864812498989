import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import Root from "./Root";
import AuthorizedApolloProvider from "./auth/AuthorizedApolloProvider";
import {GoogleOAuthProvider} from "@react-oauth/google";
import './i18n'; // Import the i18n configuration
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://d831a03b0177dca2dca7a6c6f7a5cfa4@o1040791.ingest.sentry.io/4506635984175104",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/api.easyallot\.com\//],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const googleClientId = "16204574607-r2oh33ml8sdj25tmnmcgbl96lm47pn12.apps.googleusercontent.com"

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
      <BrowserRouter>
        <AuthorizedApolloProvider>
          <Root/>
        </AuthorizedApolloProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
