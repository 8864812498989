import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../components/catalyst/table";
import WantButton from "./buttons/WantButton";
import RejectButton from "./buttons/RejectButton";
import _ from "lodash";
import {formatMoney, ItemLink} from "../../helpers";
import {Badge} from "../../components/catalyst/badge";

const Allotted = ({items, onChange}) => {

  if (_.isUndefined(items)) items = []

  const winning_bid = (item) => {
    if (item.state === "GIFT") return {}

    return _.maxBy(item.bids, bid => bid.price.amount)
  }
  const price = (bid) => formatMoney(bid.price)

  return (
    <div>
      <Table dense striped>
        <TableHead>
          <TableRow>
            <TableHeader>Item</TableHeader>
            <TableHeader className="text-right">Bid</TableHeader>
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id}>
              <TableCell className="font-medium">
                <ItemLink item={item}/>
                {
                  item.state === "GIFT" &&
                  <Badge color="blue" className="ml-2">GIFT</Badge>
                }
              </TableCell>
              <TableCell className="text-right font-mono">
                {price(winning_bid(item))}
              </TableCell>
              <TableCell className="text-zinc-500">
                <div className="flex gap-2 justify-end">
                  <WantButton item={item} onWanted={onChange} plain/>
                  <RejectButton item={item} onRejected={onChange} plain/>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default Allotted