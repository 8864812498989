import {InformationCircleIcon} from "@heroicons/react/24/solid";
import {useTranslation} from "react-i18next";


const Callout = ({textKey}) => {
  const {t} = useTranslation()

  return (
    <div className="rounded-md bg-blue-50 p-4 my-2 border-blue-200 border">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true"/>
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">{t(textKey)}</p>
          {/*<p className="mt-3 text-sm md:ml-6 md:mt-0">*/}
          {/*  <a href="#" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">*/}
          {/*    Details*/}
          {/*    <span aria-hidden="true"> &rarr;</span>*/}
          {/*  </a>*/}
          {/*</p>*/}
        </div>
      </div>
    </div>
  )
}

export default Callout