import {Link, useNavigate, useParams} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/20/solid";
import {bidIcon, formatMoney, userUrl} from "../../../helpers";
import {CrownIcon} from "lucide-react";
import {Fragment, useEffect} from 'react'
import {gql, useLazyQuery} from "@apollo/client";
import _ from "lodash";
import Discussion from "../../../components/Discussion";
import {Box} from "../../../components/Box";
import Toolbar from "./Toolbar";
import Ledger from "../../../components/Ledger";
import Bids from "./Bids";

const QUERY = gql`
  query getItem($id: ID!) {
    me {
      pictureUrl
    }
    node(id:$id) {
      ...on Item {
        canAddComment {
          value
        }
        id
        name
        bids {
          id
          price {
            currency
            amount
          }
          want
          user {
            id
            name
            email
            admin
            pictureUrl
          }
        }
        debts {
          creditor {
            admin
            name
            email
            pictureUrl
          }
          debtor {
            admin
            name
            email
            pictureUrl
          }
          amount {
            amount
            currency
          }
        }
        canWant {
          value
        }
        canReject {
          value
        }
        canBid {
          value
        }
        canDelete {
          value
        }
      }
    }
  }
`

const ItemView = ({}) => {
  const {id} = useParams()
  const navigate = useNavigate()

  const [fetchItem, {loading, error, data}] = useLazyQuery(QUERY, {
    variables: {id: id},
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    fetchItem()
  }, [])

  if (loading || error || _.isUndefined(data)) {
    return null
  }

  const {node: item} = data
  const {bids, debts, name} = item

  const canComment = item.canAddComment.value

  const onChange = () => {
  }

  const onDelete = () => {
    navigate('/inventory/');
  }

  const sortedBids = _(bids).filter(bid => bid.want).sortBy(bid => -bid.price.amount).value()

  return (
    <>
      <div>
        <nav className="sm:hidden" aria-label="Back">
          <Link to="/inventory" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>
            Back
          </Link>
        </nav>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <Link to="/inventory" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Inventory
                </Link>
              </div>
            </li>
          </ol>
        </nav>
      </div>

      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {name}
          </h2>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          <Toolbar item={item} onChange={fetchItem} onDelete={onDelete}/>
        </div>
      </div>

      {
        !_.isEmpty(sortedBids) &&
        <div className="flex flex-wrap flex-initial items-start gap-x-3 gap-y-3 mt-4">
          <Box name="Highest Bid" value={formatMoney(sortedBids[0].price)}/>

          <Bids item={item} bids={sortedBids}/>

          <Ledger debts={debts} title="Money Distribution"/>

        </div>
      }
      <div className="mt-4">
        <Discussion id={id} disabled={!canComment}/>
      </div>
    </>
  )
}

export default ItemView