import {Button} from "../../../components/catalyst/button";
import {useState} from "react";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "../../../components/catalyst/dialog";
import {Field, Label} from "../../../components/catalyst/fieldset";
import {Input} from "../../../components/catalyst/input";
import {gql, useMutation} from "@apollo/client";
import {UserPlusIcon} from "@heroicons/react/24/outline";

const SEND_INVITE = gql`
  mutation createInvite($email: String!) {
    createInvite(input: {email: $email})
  }
`

const InviteButton = ({onInvited, disabled}) => {
  let [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState("")

  const [sendInvite] = useMutation(SEND_INVITE)

  const handleSubmit = (_event) => {
    setIsOpen(false)
    sendInvite({variables: {email: value}})
    .then(response => {
      console.log("Mutation response: ", response);
      onInvited()
    })
    .catch(error => {
      console.error("Mutation error: ", error);
    });
  }

  return (
    <>
      <Button color="green" onClick={() => setIsOpen(true)} disabled={disabled}>
        <UserPlusIcon/>
        Invite
      </Button>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Invite</DialogTitle>
        <DialogDescription>
          Enter the email address of the person to invite.<br/>Must have a Google account.
        </DialogDescription>
        <DialogBody>
          <Field>
            <Label>Email</Label>
            <Input name="email" type="email" value={value} onChange={e => setValue(e.target.value)} data-1p-ignore/>
          </Field>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="green">Send Invite</Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default InviteButton