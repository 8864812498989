import {gql, useLazyQuery} from "@apollo/client";
import _ from "lodash";
import {classNames, USER_STATES} from "../../helpers";
import {useEffect, useState} from "react";
import Allotted from "./Allotted";
import Discarded from "./Discarded";
import Contested from "./Contested";
import CreateItemButton from "./buttons/CreateItemButton";
import Callout from "./Callout";
import Empty from "./Empty";
import Spinner from "../../components/Spinner";

const QUERY = gql`
  query getEstate {
    estate {
      name
      id
      canCreateItem {
        value
      }
      items {
        id
        name
        state
        userState
        bids {
          id
          price {
            amount
            currency
          }
          user {
            id
            name
          }
          want
          canBid {
            value
          }
        }
        canBid {
          value
        }
        canReject {
          value
        }
        canWant {
          value
        }
        canDelete {
          value
        }
      }
    }
    users {
      id
      email
      name
    }
    me {
      id
    }
  }
`

const Inventory = ({}) => {
  const [selectedTab, setSelectedTab] = useState(USER_STATES[0]);

  const [fetchInventory, {loading, error, data}] = useLazyQuery(QUERY, {
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    fetchInventory()
  }, []);

  if (loading || error || _.isUndefined(data)) {
    if (error) {
      console.error(error)
    } else {
      return <Spinner/>
    }
    return null
  }

  const {estate, users, me} = data
  const {items} = estate
  const tabNames = {
    "WON": "Wins",
    "TIED": "Ties",
    "LOST": "Losses",
    "REJECTED": "Passed"
  }

  const partitions = _(items).sortBy("name").groupBy('userState').value()
  const tabs = _.map(USER_STATES, (key) => ({name: tabNames[key], key: key, count: partitions[key]?.length}))

  const panels = {
    "REJECTED": <Discarded items={partitions["REJECTED"]} onChange={() => fetchInventory()}/>,
    "LOST":  <Contested items={partitions["LOST"]} users={users} me={me} onChange={() => fetchInventory()}/>,
    "TIED": <Contested items={partitions["TIED"]} users={users} me={me} onChange={() => fetchInventory()}/>,
    "WON": <Allotted items={partitions["WON"]} onChange={() => fetchInventory()}/>
  }

  return (
    <>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Inventory
          </h2>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          <CreateItemButton onCreated={() => fetchInventory()} disabled={!estate.canCreateItem.value}/>
        </div>
      </div>

      { _.isEmpty(items) && <Empty onCreated={fetchInventory} canCreate={estate.canCreateItem.value}/> }
      {!_.isEmpty(items) &&
        <div className="mt-8">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              onChange={(event) => setSelectedTab(event.target.value)}
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-brand-500 focus:outline-none focus:ring-brand-500 sm:text-sm"
              // defaultValue={tabs.find((tab) => tab.current).name}
            >
              {tabs.map((tab) => (
                <option key={tab.key}>{tab.name}</option>
              ))}
            </select>
          </div>


          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.key}
                    onClick={() => setSelectedTab(tab.key)}
                    className={classNames(
                      (tab.key === selectedTab)
                        ? 'border-brand-500 text-brand-600'
                        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                      'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium capitalize'
                    )}
                  >
                    {tab.name}
                    {tab.count ? (
                      <span
                        className={classNames(
                          (tab.key === selectedTab) ? 'bg-brand-100 text-brand-600' : 'bg-gray-100 text-gray-900',
                          'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                        )}
                      >
                    {tab.count}
                  </span>
                    ) : null}
                  </a>
                ))}
              </nav>
            </div>
          </div>
          <Callout textKey={`inventory.${selectedTab}`}/>
          <div className="mt-2 bg-white h-full min-h-lvh border-slate-100 border">
            {panels[selectedTab]}
          </div>
        </div>
      }
    </>
  )
}

export default Inventory