import {HandThumbDownIcon} from "@heroicons/react/24/solid";
import BaseWantButton from "./BaseWantButton";


const RejectButton = ({item, onRejected, ...rest}) => {

  return (
    <BaseWantButton onWanted={onRejected} item={item} value={false} {...rest}>
      <HandThumbDownIcon/>
      Don't Want
    </BaseWantButton>
  )
}

export default RejectButton