import {gql, useMutation} from "@apollo/client";
import {Button} from "../../../components/catalyst/button";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "../../../components/catalyst/dialog";
import {Field, Label} from "../../../components/catalyst/fieldset";
import {Input} from "../../../components/catalyst/input";
import {useState} from "react";
import {PlusIcon} from "@heroicons/react/24/outline";


const CREATE_ITEM = gql`
  mutation createItem($name: String!) {
    createItem(input: {name: $name})
  }
`

const CreateItemButton = ({name, onCreated, disabled}) => {
  let [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState("")

  const [createItem] = useMutation(CREATE_ITEM)

  const handleSubmit = () => {
    createItem({variables: {name: value}})
    .then(response => {
      console.log("Mutation response: ", response);
      onCreated()
    })
    .catch(error => {
      console.error("Mutation error: ", error);
    });
  }

  return (
    <>
      <Button  onClick={() => setIsOpen(true)} color="green" disabled={disabled}>
        <PlusIcon />
        Add Item
      </Button>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Add Item</DialogTitle>
        <DialogDescription>
          Enter the name of the new item.
        </DialogDescription>
        <DialogBody>
          <Field>
            <Label>Name</Label>
            <Input name="name" value={value} onChange={e => setValue(e.target.value)}/>
          </Field>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="green">Add</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateItemButton