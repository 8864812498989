import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../components/catalyst/table";
import WantButton from "./buttons/WantButton";
import RejectButton from "./buttons/RejectButton";
import _ from "lodash";
import {bidIcon, classNames, formatMoney, ItemLink} from "../../helpers";
import BidButton from "./buttons/BidButton";


const Contested = ({items, users, me, onChange}) => {

  const currentUser = me

  if (_.isUndefined(items)) items = []

  return (
    <div>
      <Table dense striped>
        <TableHead>
          <TableRow>
            <TableHeader>Item</TableHeader>
            {users.map(user => {
              const own = user.id === currentUser.id

              return (
                <TableHeader key={user.id} className={classNames(own ? "border-x-4 bg-amber-50" : "", "text-right")} >
                  {user.name}
                </TableHeader>
              )
            })
            }
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => {
            const userBid = _.find(item.bids, bid => bid.user.id === currentUser.id)
            const userPrice = _.isUndefined(userBid) ? 0 : userBid.price.amount

            return (
              <TableRow key={item.id}>
                <TableCell className="font-medium">
                  <ItemLink item={item}/>
                </TableCell>

                {users.map(user => {
                  const bid = _.find(item.bids, bid => bid.user.id === user.id)
                  const contestant = !_.isNil(bid)
                  const icon = bidIcon(item, user)
                  const own = user.id === currentUser.id

                  return (
                    <TableCell key={user.id} className={classNames(own ? "border-x-4 bg-amber-50 " : "")}>
                      <div className="flex items-center justify-end gap-x-1 font-mono">
                        {formatMoney(bid?.price)}
                        {contestant && icon}
                      </div>
                    </TableCell>
                  )
                })}

                <TableCell className="text-zinc-500">
                  <div className="flex gap-2 justify-end">
                    <BidButton item={item} onBid={onChange} defaultValue={userPrice} plain/>
                    <WantButton item={item} onWanted={onChange} plain/>
                    <RejectButton item={item} onRejected={onChange} plain/>
                  </div>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default Contested