import {useTranslation} from "react-i18next";
import HelpBadge from "../../components/HelpBadge";

const StateBadge = ({state}) => {

  const {t} = useTranslation()

  const ESTATE_COLORS = {
    "NEGOTIATION": "bg-sky-50 text-sky-700 ring-sky-700/10",
    "READY": "bg-yellow-50 text-yellow-800 ring-yellow-600/20",
    "SETTLED": "bg-blue-50 text-blue-700 ring-blue-700/10"
  }

  const color = ESTATE_COLORS[state] + " inline-flex items-center rounded-md font-medium ring-1 ring-inset px-2 py-1 capitalize text-sm"
  return (
    <span className={color}>
      {state.toLowerCase()}

      <HelpBadge text={t(`estate.state.${state.toLowerCase()}`)}/>
    </span>
  )
}

export default StateBadge