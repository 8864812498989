import {userUrl} from "../../helpers";
import {Link} from "react-router-dom";
import {CrownIcon} from "lucide-react";


const PeopleTable = ({users, onChange}) => {
  return (
    <div className="bg-white p-4 rounded-md shadow">
      <div className="border-b border-gray-200 pb-5">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Estate Members</h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">

        </p>
      </div>
      <ul role="list" className="divide-y divide-gray-100">
        {users.map((person) => (
          <li key={person.email} className="flex justify-between gap-x-6 py-5">
            <div className="flex min-w-0 gap-x-4">
              <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.pictureUrl} alt=""/>
              <div className="min-w-0 flex-auto">
                <div className="text-sm font-semibold leading-6 text-gray-900 flex items-center ">
                  <Link to={userUrl(person)}>
                    {person.name}
                  </Link>

                  {person.admin && <div title="admin"><CrownIcon className="size-4 ml-1 text-yellow-600"/></div>}
                </div>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.email}</p>

              </div>
            </div>
            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              {/*<p className="text-sm leading-6 text-gray-900">{person.role}</p>*/}
              {/*{person.lastSeen ? (*/}
              {/*  <p className="mt-1 text-xs leading-5 text-gray-500">*/}
              {/*    Last seen <time dateTime={person.lastSeenDateTime}>{person.lastSeen}</time>*/}
              {/*  </p>*/}
              {/*) : (*/}
              {/*  <div className="mt-1 flex items-center gap-x-1.5">*/}
              {/*    <div className="flex-none rounded-full bg-emerald-500/20 p-1">*/}
              {/*      <div className="h-1.5 w-1.5 rounded-full bg-emerald-500"/>*/}
              {/*    </div>*/}
              {/*    <p className="text-xs leading-5 text-gray-500">Online</p>*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PeopleTable