import {Route, Routes} from "react-router-dom";
import App from "./App";
import Estate from "./pages/estate";
import People from "./pages/people";
import Person from "./pages/people/view"
import Inventory from "./pages/inventory";
import ItemView from "./pages/inventory/view"
import {useAuth} from "./auth/useAuth";
import Login from "./login";
import Logout from "./login/Logout";

const Root = () => {
  const {isAuthenticated} = useAuth()
  console.log(`authenticated: ${isAuthenticated}`)

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element={<Login/>}/>
      </Routes>
    )
  } else {
    return (
      <Routes>
        <Route element={<App/>}>
          <Route path="/logout" element={<Logout/>}/>

          <Route path="estate?" element={<Estate/>}/>
          <Route path="inventory" element={<Inventory/>}/>
          <Route path="inventory/:id" element={<ItemView/>}/>
          <Route path="people" element={<People/>}/>
          <Route path="people/:id" element={<Person/>}/>

          <Route
            path="*"
            element={<main style={{padding: "1rem"}}><p>There's nothing here!</p></main>}
          />
        </Route>
      </Routes>
    )
  }
}

export default Root
