import {classNames} from "../helpers";
import HelpBadge from "./HelpBadge";
import {useTranslation} from "react-i18next";


export const Box = ({name, value, help = null,  className = null}) => {
  const { t } = useTranslation();

  return (
    <div key={name} className={classNames( className, " rounded-lg bg-white px-4 py-5 shadow sm:p-6 min-w-32 ")}>
      <dt className=" text-sm font-medium text-gray-500 gap-2 flex items-center">
        {name}
        {help && <HelpBadge text={t(help)}/> }
      </dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{value}</dd>
    </div>
  )
}