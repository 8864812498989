import {HandRaisedIcon} from "@heroicons/react/24/solid";
import {Button} from "../../../components/catalyst/button";
import {useState} from "react";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "../../../components/catalyst/dialog";
import {Description, Field, Label} from "../../../components/catalyst/fieldset";
import {Input} from "../../../components/catalyst/input";
import {gql, useMutation} from "@apollo/client";

const UPDATE_BID = gql`
  mutation updateBid($id: ID!, $price: Float!) {
    bidItem(input: {id: $id, priceAmount: $price})
  }
`

const BidButton = ({item, onBid, defaultValue = 0, ...rest}) => {
  let [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(defaultValue.toString())

  const [updateBid] = useMutation(UPDATE_BID)

  const allowed = item.canBid.value

  const handleSubmit = (_event) => {
    setIsOpen(false)
    updateBid({variables: {id: item.id, price: parseFloat(value)}})
    .then(response => {
      console.log("Mutation response: ", response);
      onBid(item)
    })
    .catch(error => {
      console.error("Mutation error: ", error);
    });
  }

  return (
    allowed &&
    <>
      <Button {...rest} onClick={() => setIsOpen(true)} disabled={!allowed}>
        <HandRaisedIcon/>
        Bid
      </Button>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Enter Your Bid</DialogTitle>
        <DialogDescription>
          The highest bidder gets to keep the item, while others get an equal share of the winning bid as credit.<br/>
          For example, if Bob bids $100 against Alice's $80, Bob gets the item and pays Alice $50 from his bid.
        </DialogDescription>
        <DialogBody>
          <Field>
            <Label>Amount</Label>
            <Description>How much value do you place on this item? </Description>
            <Input name="amount" placeholder="$0.00" value={value} onChange={e => setValue(e.target.value)}/>
          </Field>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="green">Enter Bid</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BidButton