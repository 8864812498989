import {Link} from "react-router-dom";
import {bidIcon, formatMoney, userUrl} from "../../../helpers";
import {CrownIcon} from "lucide-react";


const Bids = ({bids, item}) => {

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="border-b border-gray-200 pb-5">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Bids
        </h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
        </p>
      </div>
      <ul role="list" className="divide-y divide-gray-100">
        {bids.map((bid) => (
          <li key={bid.id} className="flex justify-between gap-x-6 py-5">
            <div className="flex min-w-0 gap-x-4">
              <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={bid.user.pictureUrl} alt=""/>
              <div className="min-w-0 flex-auto">
                <div className="text-sm font-semibold leading-6 text-gray-900 flex items-center ">
                  <Link to={userUrl(bid.user)}>
                    {bid.user.name}
                  </Link>

                  {bid.user.admin &&
                    <div title="admin"><CrownIcon className="size-4 ml-1 text-yellow-600"/></div>}
                </div>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{bid.user.email}</p>

              </div>
            </div>
            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p className="text-sm leading-6 text-gray-900">
                {formatMoney(bid.price)}
              </p>
              <p className="mt-1 text-xs leading-5 text-gray-500">
                {bidIcon(item, bid.user)}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )

}

export default Bids