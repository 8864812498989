import {HandThumbUpIcon} from "@heroicons/react/24/solid";
import BaseWantButton from "./BaseWantButton";

const WantButton = ({item, onWanted, ...rest}) => {

  return (
    <BaseWantButton onWanted={onWanted} item={item} value={true} {...rest}>
      <HandThumbUpIcon/>
      Want
    </BaseWantButton>
  )
}

export default WantButton