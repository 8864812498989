import WantButton from "../buttons/WantButton";
import RejectButton from "../buttons/RejectButton";
import BidButton from "../buttons/BidButton";
import DeleteItemButton from "../buttons/DeleteItemButton";

const Toolbar = ({item, onChange, onDelete}) => {
  return (
    <div className="flex items-center gap-2">
      <WantButton item={item} color="white" onWanted={onChange}/>
      <RejectButton item={item} color="white" onRejected={onChange}/>
      <BidButton item={item} color="white" onBid={onChange}/>
      <DeleteItemButton item={item} onDeleted={onDelete} />
    </div>
  )
}

export default Toolbar