import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../components/catalyst/table";
import WantButton from "./buttons/WantButton";
import RejectButton from "./buttons/RejectButton";
import _ from "lodash";
import {ItemLink} from "../../helpers";


const Discarded = ({items, onChange}) => {


  if (_.isUndefined(items)) items = []

  return (
    <div>
      <Table dense striped>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id}>
              <TableCell className="font-medium"><ItemLink item={item}/></TableCell>
              <TableCell className="text-zinc-500">
                <div className="flex gap-2 justify-end">
                  <WantButton item={item} onWanted={onChange} plain/>
                  <RejectButton item={item} onRejected={onChange} plain/>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default Discarded