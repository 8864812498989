import {classNames} from "../../helpers";

const Locks = ({locks}) => {

  return (
    <div className="flex gap-x-3 overflow-hidden p-2">
      {locks.map((lock, i) => (
        <span key={i} className="relative inline-block" >
          <img className="size-12 rounded-md"  src={lock.user.pictureUrl} alt={lock.user.name} />
          <span className={classNames(lock.locked ? "bg-green-400": "bg-gray-300", "absolute right-0 top-0 block h-4 w-4 -translate-y-1/2 translate-x-1/2 transform rounded-full ring-2 ring-white")}    />

        </span>
      ))}
    </div>)
}

export default Locks